import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import DataTable from './DataTable';
import CreateTask from './CreateTask/CreateTask';
import './Dashboard.css';
import MaterialUISidebar from './MaterialUISidebar/MaterialUISidebar';
import { useNavigate } from 'react-router-dom';
import useToken from '../../useToken';

export function Dashboard() {
    // go to login page if not logged in
    const { token } = useToken();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [token, navigate]);

    const [selectedTab, setSelectedTab] = useState('dashboard');
    const handleTabChange = (index) => {
        if (!token) {
            navigate('/login');
        }
        setSelectedTab(index);
    };

    const getContent = () => {
        switch (selectedTab) {
            case 'dashboard':
                return <DataTable key={selectedTab} />;
            case 'create':
                return <CreateTask />;
            default:
                return <DataTable key={selectedTab} />;
        }
    };


    return (
  <div className='dashboard-container'>
        <MaterialUISidebar handleTabChange={handleTabChange} />
            <Container component="main"
                maxWidth="lg"
                sx={{
                    marginLeft: '200px', // Adjust the width of the sidebar
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    marginTop: '60px',
                    marginBottom: '20px',
                }}>
                {getContent()}
            </Container>

        </div>
    );
}




