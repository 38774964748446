import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { LOGIN_URL } from '../api';
import PropTypes from 'prop-types';
import './Login.css';

const BACKEND_TOKEN_URL = `${LOGIN_URL}/auth/token`;
const BACKEND_VERIFY_URL = `${LOGIN_URL}/auth/verify`;

export const Login = ({ setToken }) => {
  const [loginFailedMessage, setLoginFailedMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasCodeBeenProcessed, setHasCodeBeenProcessed] = useState(false);

  
  const navigate = useNavigate();
  const location = useLocation();


  // Redirect to Cognito login on component mount
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    
    if (code && !hasCodeBeenProcessed)  {
      // Handle the authorization code from Cognito
      handleCognitoCode(code);
      setHasCodeBeenProcessed(true);
    } else if (!code) {
      // Redirect to Cognito login if no code is present
      redirectToCognitoLogin();
    }
  }, [location, hasCodeBeenProcessed]);

  // Handle Cognito authorization code
  const handleCognitoCode = async (code) => {
    if (!code) {
      console.error("No authorization code provided.");
      return;
    }
  
    setIsLoading(true);
    console.log("Starting Cognito authentication flow...");
  
    try {
      // Step 1: Exchange authorization code for tokens via backend
      const tokenResponse = await fetch(BACKEND_TOKEN_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ code }),
      });
  
      if (!tokenResponse.ok) {
        throw new Error(`Token exchange failed: ${tokenResponse.status}`);
      }

      const tokenData = await tokenResponse.json(); 
      console.log("Received token data:", tokenData);
  
      const { access_token, id_token } = tokenData;
      // if (!access_token || !id_token) {
      //   throw new Error("Missing tokens in response.");
      // }
      console.log("Tokens received from backend.");
  
      // Step 2: Verify tokens with backend
      const verifyResponse = await fetch(BACKEND_VERIFY_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: access_token, id_token }),
      });
  
      if (!verifyResponse.ok) {
        throw new Error(`Token verification failed: ${verifyResponse.status}`);
      }
  
      const verifiedData = await verifyResponse.json();
      console.log("Token verified successfully.");
  
      // Step 3: Store token and navigate
      setToken(verifiedData);
      navigate("/dashboard");
    } catch (error) {
      console.error("Authentication error:", error.message);
      setLoginFailedMessage(`Authentication failed: ${error.message}`);
      setTimeout(() => {
        navigate("/login");
      }, 50000);
    } finally {
      setIsLoading(false);
    }
  };

  // Redirect to Cognito login
  const redirectToCognitoLogin = () => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const redirectUri = isDevelopment 
      ? 'http://localhost:3000/login' 
      : 'https://serana.ai/login';
    
    const cognitoLoginUrl = `https://auth.serana.ai/login/continue?client_id=48lemqjcaej3c2t03tdgo3dapo&response_type=code&scope=email+openid+profile&redirect_uri=${encodeURIComponent(redirectUri)}`;
    window.location.href = cognitoLoginUrl;
  };

  return (
    <Container component="main" maxWidth="xs" className="login-container">
      <div>
        <Typography variant="h4" className="login-heading">
          Login
        </Typography>
        
        {isLoading ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <CircularProgress />
            <Typography variant="body1" style={{ marginTop: '10px' }}>
              Processing your login...
            </Typography>
          </div>
        ) : (
          <Typography variant="body1" className="login-description">
            Redirecting to login service...
          </Typography>
        )}
        
        {loginFailedMessage && (
          <Typography variant="body2" color="error" className="login-error">
            {loginFailedMessage}
          </Typography>
        )}
        
      </div>
    </Container>
  );
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
}; 